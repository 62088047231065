<template>
	<div class="bordaappbar">
		<div class="mt-3 ml-5">
			<v-row>
				<v-col md="2" lg="2" offset="0">
					<dtPicker label="Data Inicio" v-model="sFirstDay" v-on:click="HideReport()"></dtPicker>
				</v-col>
				<v-col md="2" lg="2">
					<dtPicker label="Data Fim" v-model="sLastDay" v-on:click="HideReport()"></dtPicker>
				</v-col>
				<v-col md="2" lg="2">
					<v-btn width="250" color="primary" @click="MostraFaturaveis()">Listar Faturáveis</v-btn>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="4" offset="0">
					<v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="Procurar Caso/Cliente" v-model="sBusca" v-on:keyup.enter="MostraFaturaveis()"></v-text-field>
				</v-col>
				<v-col cols="2">
					<v-switch v-if="ShowDados" v-model="sSobreescrer" dense false-value="false" true-value="true" label="Sobreescrever preliminar" hide-details inset></v-switch>
				</v-col>
			</v-row>
			<v-row v-if="ShowDados">
				<v-col cols="2" offset="0">
					<v-autocomplete :items="AdvogadosFaturaveis" item-text="Advogado" item-value="Advogado" v-model="AdvSelecionado" label="Advogados Listados" dense outlined hide-details></v-autocomplete>
				</v-col>
				<v-col cols="2">
					<v-btn color="primary" @click="SelecionarLancamentos()">Selecionar Advogados</v-btn>
				</v-col>
				<v-col>
					<v-btn v-if="ShowDados" color="primary" @click="ImprimePreliminar()">Emitir Preliminares Selecionados</v-btn>
				</v-col>
			</v-row>

			<v-row align="center" justify="center" class="mb-5">
				<v-spacer></v-spacer>
				<v-spacer></v-spacer>
			</v-row>
		</div>
		<div>
			<!--LISTA DE FATURÁVEIS-->
			<v-row v-if="ShowFaturaveis">
				<v-col cols="12">
					<v-data-table disable-pagination class="elevation-1 row-pointer" v-model="selected" show-select :single-select="singleSelect" :headers="HeaderFaturaveis" :items="GridFaturaveis" item-key="IdCaso" :hide-default-header="false" :hide-default-footer="true" @click:row="rowClick" :key="refreshTable">
						<template v-slot:[`item.TotalLancamentos`]="{ item }">
							<span class="text-no-wrap">
								{{ FormataMoeda(item.TotalLancamentos, item.MoedaFaturamento) }}
							</span>
						</template>

						<template v-slot:[`item.TotalDespesas`]="{ item }">
							<span class="text-no-wrap">
								{{ FormataMoeda(item.TotalDespesas, item.MoedaFaturamento) }}
							</span>
						</template>

						<template v-slot:[`item.DataPreliminar`]="{ item }">
							<span class="text-no-wrap">
								{{ item.DataPreliminar | dateBR }}
							</span>
						</template>

						<template v-slot:[`item.MoedaFaturamento`]="{ item }">
							<span class="text-no-wrap">
								<span v-if="item.MoedaFaturamento == 1">R$</span>
								<span v-if="item.MoedaFaturamento == 2">U$</span>
							</span>
						</template>

						<template v-slot:[`item.Action`]="{ item }">
							<v-btn dense @click="SelecionaCaso(item)" color="primary" enabled="IdPreliminar>0">Emitir Preliminar</v-btn>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import moment, { relativeTimeThreshold } from "moment";

export default {
	name: "Lancamento",
	data() {
		return {
			singleSelect: false,
			selected: [],
			AdvogadosFaturaveis: [],
			AdvSelecionado: [],
			sFirstDay: null,
			sLastDay: null,
			sSobreescrer: false,
			EmitirFatura: false,
			ShowFaturaveis: false,
			Today: null,
			ShowDados: false,
			GridData: [],
			GridDespesas: [],
			GridLancamentos: [],
			GridFaturaveis: [],
			GridSelected: [],
			refreshTable: 0,
			sBusca: "",
			HeaderFaturaveis: [
				{ text: "kk", width: "1%", value: "data-table-select" },
				{ text: "Cliente", value: "Cliente", align: "left", width: "20%", sortable: false },
				{ text: "Impressao", value: "NomeImpressao", width: "20%", sortable: false },
				{ text: "Caso", value: "Caso", width: "15%", sortable: false },
				{ text: "Advogado", value: "Advogado", width: "10%", sortable: false },
				{ text: "Lançamentos", value: "TotalLancamentos", align: "center", width: "5%", sortable: false },
				{ text: "Despesas", value: "TotalDespesas", align: "center", width: "5%", sortable: false },
				{ text: "Preliminar", value: "DataPreliminar", align: "center", width: "5%", sortable: false },
				{ text: "", value: "Action", sortable: false },
			],
			HeaderLancamento: [
				{ text: "Date", value: "DataLancamento", align: "center", width: "10%", sortable: false },
				{ text: "Comment", value: "Descricao", width: "70%", sortable: false },
				{ text: "Hours", value: "HorasCobranca", align: "right", width: "10%", sortable: false },
				{ text: "Amount", value: "ValorLancamentoCalculado", align: "right", width: "10%", sortable: false },
			],
			HeaderDespesa: [
				{ text: "Date", value: "Data", align: "center", width: "10%", sortable: false },
				{ text: "Expenses", value: "Descricao", width: "80%", sortable: false },
				{ text: "Amount", value: "Valor", align: "center", width: "10%", sortable: false },
			],
			RowsPerPageItems: [5, 10, 20, 30, 40, 50, 100, 200],
			Pagination: {
				page: 1,
				itemsPerPage: 100,
				pageCount: 1,
				itemsLength: 0,
				sortBy: ["IdCliente"],
				sortDesc: [true],
				Filtro: {
					Busca: "",
				},
			},
			dialogm1: "",
			dialog: false,
		};
	},
	components: {},
	methods: {
		exportToPDF() {
			var element = document.getElementById("element-to-print");
			html2pdf(element, {
				margin: 0,
				filename: "document.pdf",
				image: { type: "jpeg", quality: 0.98 },
				html2canvas: { dpi: 192, letterRendering: true },
				jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
			});
		},
		FormataMoeda(valor, moeda) {
			var sPreMoeda = "";
			if (moeda == 1) {
				sPreMoeda = "R$ ";
			} else if (moeda == 2) {
				sPreMoeda = "U$ ";
			}
			return sPreMoeda.concat(
				parseFloat(valor)
					.toFixed(2)
					.replace(".", ",")
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
			);
		},
		rowClick(item, row) {
			let result = this.GridFaturaveis.find((e) => e.IdCaso == item.IdCaso);
			this.selected.push(result);
		},

		MostraFaturamento() {
			this.ShowFaturaveis = false;
			axios.get("/api/Faturamento/AFaturar/".concat(this.sFirstDay).concat("/").concat(this.sLastDay)).then((response) => {
				this.GridData = response.data;
				axios.get("/api/Faturamento/ListaDespesas/".concat(this.sFirstDay).concat("/").concat(this.sLastDay).concat("/0")).then((response) => {
					this.GridDespesas = response.data;
					axios.get("/api/Faturamento/ListaLancamentos/".concat(this.sFirstDay).concat("/").concat(this.sLastDay).concat("/0")).then((response) => {
						this.GridLancamentos = response.data;
						this.EmitirFatura = true;
					});
				});
			});
		},

		MostraFaturaveis() {
			axios.get("/api/Faturamento/Faturaveis/".concat(this.sFirstDay).concat("/").concat(this.sLastDay).concat("/").concat(this.sBusca)).then((response) => {
				this.GridFaturaveis = response.data;
				this.EmitirFatura = false;
				this.ShowFaturaveis = true;
				this.ShowDados = true;

				this.AdvogadosFaturaveis = this.GridFaturaveis.filter((item, pos, self) => self.findIndex((v) => v.Advogado === item.Advogado) === pos);

				this.AdvogadosFaturaveis = this.AdvogadosFaturaveis.sort((a, b) => {
					const nameA = a.Advogado.toUpperCase(); // ignore upper and lowercase
					const nameB = b.Advogado.toUpperCase(); // ignore upper and lowercase
					if (nameA < nameB) {
						return -1;
					}
					if (nameA > nameB) {
						return 1;
					}
					return 0;
				});
			});
		},

		// Imprimo as preliminares em PDF e envio para os Advogados
		ImprimePreliminar() {
			var sTemSobrescrita = 0;
			if (this.selected.length > 0) {
				var CasosSelected = [];
				for (var k in this.selected) {
					CasosSelected.push(this.selected[k]["IdCaso"]);
					if (this.selected[k]["DataPreliminar"] != null) {
						sTemSobrescrita += 1;
						if (!this.sSobreescrer) {
							this.$toast.warning("Existem Casos com lançamentos alterados na preliminar, selecione a opção de sobrescrever.", { timeout: 5000 });
							return;
						}
					}
				}
				axios({ method: "post", data: CasosSelected, url: "/api/Preliminares/ImprimirPreliminar/".concat(this.sFirstDay).concat("/").concat(this.sLastDay).concat("/") }).then((response) => {
					var sRetorno = response.data.Success;
					// debugger;
					if (sRetorno) {
						this.$toast.success("Preliminar geradas em PDFs e enviadas por e-mail aos responsáveis...", { timeout: 5000 });
						this.MostraFaturaveis();
						return;
					} else {
						this.$toast.error("Erro ao copiar dados para Preliminar", { timeout: 5000 });
						this.MostraFaturaveis();
						return;
					}
				});
			} else {
				this.$toast.warning("Não existem Casos selecionados.", { timeout: 5000 });
			}
		},

		SelecionarLancamentos() {
			if (this.AdvSelecionado.length > 0) {
				this.GridFaturaveis.forEach((v) => {
					if (v.Advogado == this.AdvSelecionado) {
						this.selected.push(v);
					}
				});
			} else {
				this.selected = this.GridFaturaveis;
			}
			this.$toast.success("Lançamentos Selecionados...", { timeout: 5000 });
		},
		SelecionaCaso(item) {
			this.selected = [];
			this.GridFaturaveis.forEach((v) => {
				if (v.IdCaso == item.IdCaso) {
					this.selected.push(v);
				}
			});
			this.ImprimePreliminar();
		},
		Edit(Id) {
			this.$router.push("/Lancamentos/Edit/".concat(Id));
		},
		Index() {
			//Pega os dados da controller, action Index
			axios.post("/api/Lancamentos/Index", this.Pagination).then((response) => {
				//Pega os dado do Grid
				this.GridData = response.data.Data;

				//Pega os dados da Paginação
				this.Pagination = response.data.Pagination;
			});
		},
		RowClass(item) {
			if (item.Ativo == false) {
				return "rowdisabled";
			}
		},
	},
	mounted: function () {
		console.clear();
		let now = moment();
		this.Today = now.format("YYYY-MM-DD");
		this.sLastDay = moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");
		this.sFirstDay = moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
	},
};
</script>
//
