<style scoped>
.wall-switch {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.switch-container {
	display: flex;
	align-items: center;
	gap: 16px; /* Espaçamento entre os elementos */
}

.labelleft {
	font-size: 16px;
	align-items: right;
	margin-top: 5px;
}
.labelright {
	font-size: 16px;
	align-items: left;
	margin-top: 5px;
}

.custom-switch {
	margin: 0;
	--v-switch-width: 50px;
	--v-switch-height: 28px;
}

/* Customização para manter a mesma cor */
.same-color-switch .v-input__control {
	background-color: #4caf50 !important; /* Cor do fundo do switch */
	border-color: #4caf50 !important; /* Cor da borda */
}

.same-color-switch .v-input__control:hover {
	background-color: #45a049 !important; /* Cor ao passar o mouse */
}

.same-color-switch .v-input--selection-controls__ripple {
	background-color: #4caf50 !important; /* Cor do efeito ripple */
}

.same-color-switch .v-input__control:before {
	background-color: #fff !important; /* Cor do botão deslizante */
}

.same-color-switch .v-input__control:after {
	background-color: #fff !important; /* Cor do botão deslizante */
}
</style>

<template>
	<div class="bordaappbar">

		<v-data-table class="elevation-1 row-pointer" :headers="Header" mobile-breakpoint="1" :items="GridData" :item-class="RowClass" :sort-by.sync="Pagination.sortBy" :must-sort="true" :sort-desc.sync="Pagination.sortDesc" item-key="Id" :options.sync="Pagination" :server-items-length="Pagination.itemsLength" :footer-props="{ itemsPerPageOptions: RowsPerPageItems }" v-on:update:page="Index()" v-on:update:items-per-page="Index()" v-on:update:sort-by="Index()" v-on:update:sort-desc="Index()">
			<template v-slot:top>
				<v-toolbar dense flat class="mt-5">
					<v-col cols="2">
						<v-autocomplete return-object :items="ListaAdvogados" item-text="Nome" item-value="Id" v-model="selListaAdvogados" label="Advogado" dense outlined hide-details></v-autocomplete>
					</v-col>

					<v-col cols="2">
						<dtPicker label="Data Inicial" v-model="DataInicio" v-on:click="Index()"></dtPicker>
					</v-col>
					<v-col cols="2">
						<dtPicker label="Data Final" v-model="DataFim" v-on:click="Index()"></dtPicker>
					</v-col>

					<v-col cols="2">
						<v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="Procurar" v-model="Pagination.Filtro.Busca" v-on:keyup.enter="Index()"></v-text-field>
					</v-col>
					<v-col cols="2">
						<div class="wall-switch">
							<div class="switch-container">
								<span class="labelleft">Quitados</span>
								<v-switch v-model="Pagination.Filtro.EmAberto" dense inset class="custom-switch same-color-switch" false-value="false" true-value="true" hide-details @click="Index()"></v-switch>
								<span class="labelright">Em aberto</span>
							</div>
						</div>
					</v-col>
					<v-btn color="primary" @click="Index()">Filtrar</v-btn>
				</v-toolbar>
				<br />
			</template>

			<template v-slot:[`item.Referencia`]="{ item }">
				<span class="text-no-wrap">{{ item.ReferenciaNumero }}-{{ item.ReferenciaAno }}</span>
			</template>

			<template v-slot:[`item.ClienteCaso`]="{ item }">
				<span class="text-no-wrap">
					{{ item.FaturamentoControle.Cliente.Nome }}
					<br />
					&nbsp;&nbsp;&nbsp;&nbsp;{{ item.FaturamentoControle.Caso.Nome }}
				</span>
			</template>

			<template v-slot:[`item.DataGeracao`]="{ item }">
				<span class="text-no-wrap">
					{{ item.DataGeracao | dateBR }}
				</span>
			</template>

			<template v-slot:[`item.ValorLancamento`]="{ item }">
				<span class="text-no-wrap">
					{{ FormataMoeda(item.ValorLancamento, item.MoedaFaturamento) }}
				</span>
			</template>

			<template v-slot:[`item.PorcentagemComissaoAtuacao`]="{ item }">
				<span class="text-no-wrap">{{ item.PorcentagemComissaoAtuacao }} %</span>
			</template>

			<template v-slot:[`item.ValorComissaoAtuacao`]="{ item }">
				<span class="text-no-wrap">
					{{ FormataMoeda(item.ValorComissaoAtuacao, item.MoedaFaturamento) }}
				</span>
			</template>

			<template v-slot:[`item.PorcentagemComissaoIndicacao`]="{ item }">
				<span class="text-no-wrap">{{ item.PorcentagemComissaoIndicacao }} %</span>
			</template>

			<template v-slot:[`item.ValorComissaoIndicacao`]="{ item }">
				<span class="text-no-wrap">
					{{ FormataMoeda(item.ValorComissaoIndicacao, item.MoedaFaturamento) }}
				</span>
			</template>

			<template v-slot:[`item.ValorFaturado`]="{ item }">
				<span class="text-no-wrap">
					{{ FormataMoeda(item.ValorFaturado, item.MoedaFaturamento) }}
				</span>
			</template>

			<template v-slot:[`item.ValorDolar`]="{ item }">
				<span class="text-no-wrap">
					{{ FormataMoeda(item.ValorDolar, 1) }}
				</span>
			</template>

			<template v-slot:[`item.ValorFinal`]="{ item }">
				<span class="text-no-wrap">
					{{ FormataMoeda(item.ValorFinal, 1) }}
				</span>
			</template>

			<template v-slot:[`item.DataPagamento`]="{ item }">
				<span class="text-no-wrap" v-if="item.ValorPago > 0">
					{{ item.DataPagamento | dateBR }}
				</span>
				<span v-else>
					<v-icon @click="BaixaClick(item)" color="primary" title="Efetuar Baixa do Pagamento">mdi-account-cash</v-icon>
				</span>
			</template>

			<template v-slot:[`item.ValorPago`]="{ item }">
				<span class="text-no-wrap">
					{{ FormataMoeda(item.ValorPago, 1) }}
				</span>
			</template>

			<template slot="body.append">
				<tr style="background-color: #e0e0e0; color: black; font-weight: bold">
					<td colspan="11" align="center">Totalizadores</td>
					<!-- <td align="right">{{ totalLancamentos }}</td> -->
					<!-- <td colspan="3"></td> -->
					<td align="right">{{ FormataMoeda(totalAReceber, 1) }}</td>
					<td colspan="3"></td>
				</tr>
			</template>
		</v-data-table>

		<!-- DIALOG DE BAIXA DE PAGAMENTO -->
		<v-dialog v-model="DialogBaixa" width="800px">
			<v-card>
				<v-app-bar color="primary">
					<v-toolbar-title class="white--text">Regstro de Pagamento de Comissão</v-toolbar-title>
				</v-app-bar>
				<v-card-text>
					<v-container class="pt-10">
						<template>
							<v-row>
								<v-col cols="12" lg="6" xl="6">
									<dtPicker label="Data Pagamento" v-model="editedItem.DataPagamento"></dtPicker>
								</v-col>
								<v-col cols="12" lg="6" xl="6">
									<v-currency-field :prefix="'R$'" outlined dense hide-details label="Valor Pago" v-model="editedItem.ValorPago"></v-currency-field>
								</v-col>
							</v-row>
						</template>
					</v-container>
				</v-card-text>
				<v-card-actions class="pt-10 pb-10">
					<v-spacer></v-spacer>
					<v-btn x-large color="error" @click="DialogBaixa = false">Cancelar</v-btn>
					<v-btn x-large color="primary" @click="BaixaSave()">OK</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
	name: "Faturamento",
	data() {
		return {
			GridData: [],
			totalAReceber: 0,
			DataInicio: null,
			DataFim: null,
			totalLancamentos: 0,
			DialogBaixa: false,
			ListaAdvogados: [],
			selListaAdvogados: [],
			editedItem: [],
			itemFaturamento: [],
			ShowExcluiFaturamento: false,
			Header: [
				{ text: "Id", value: "Id", sortable: false, align: "center" },
				{ text: "Advogado", value: "Advogado.Nome", sortable: false, align: "left", width: "250px" },
				{ text: "Cliente/Caso", value: "ClienteCaso", sortable: false, align: "left", width: "250px" },
				{ text: "Referência", value: "Referencia", sortable: false, align: "center" },
				{ text: "Gerado em", value: "DataGeracao", sortable: false, align: "center" },
				{ text: "Indicação", value: "PorcentagemComissaoIndicacao", sortable: false, align: "center", width: "60px" },
				{ text: "Valor Indicação", value: "ValorComissaoIndicacao", sortable: false, align: "right", width: "120px" },
				{ text: "Valor Lançamentos", value: "ValorLancamento", sortable: false, align: "right", width: "120px" },
				{ text: "Autuação", value: "PorcentagemComissaoAtuacao", sortable: false, align: "center", width: "60px" },
				{ text: "Valor Autuação", value: "ValorComissaoAtuacao", sortable: false, align: "right", width: "120px" },
				{ text: "Câmbio", value: "ValorDolar", sortable: false, align: "center" },
				{ text: "Valor a Receber", value: "ValorFinal", sortable: false, align: "right", width: "120px" },
				{ text: "Pagamento", value: "DataPagamento", sortable: false, align: "center" },
				{ text: "Valor Pago", value: "ValorPago", sortable: false, align: "right", width: "120px" },
				{ text: "", value: "Action", sortable: false },
			],
			RowsPerPageItems: [5, 10, 20, 30, 40, 50, 100, 200],
			Pagination: {
				page: 1,
				itemsPerPage: 50,
				pageCount: 1,
				itemsLength: 0,
				sortBy: ["Id"],
				sortDesc: [true],
				Filtro: {
					Busca: "",
					EmAberto: "true",
					DataInicio: null,
					DataFim: null,
					IdAdvogado: null,
				},
			},
		};
	},
	components: {},
	methods: {
		BaixaClick: function (item) {
			// this.editedItem = item;
			this.editedItem = Object.assign({}, item);
			if (this.editedItem.ValorPago == 0) {
				this.editedItem.DataPagamento = moment().format("YYYY-MM-DD");
			}
			this.editedItem.ValorPago = this.editedItem.ValorFinal;
			this.DialogBaixa = true;
		},
		BaixaSave(item) {
			console.log("BR ", this.editedItem.ValorPago);
			// this.editedItem.ValorPago = this.ConverteMoedaUS(this.editedItem.ValorPago);
			console.log("US ", this.editedItem.ValorPago);
			console.log(this.editedItem);
			axios({ method: "post", data: this.editedItem, url: "/api/Comissionamentos/Save" })
				.then((response) => {
					this.$toast.success("Fatura baixada no sistema.", { timeout: 4000 });
					this.DialogBaixa = false;
					this.Index();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		Index() {
			// debugger;
			this.Pagination.Filtro.DataInicio = this.DataInicio;
			this.Pagination.Filtro.DataFim = this.DataFim;
			this.Pagination.Filtro.IdAdvogado = this.selListaAdvogados.Id;
			//Pega os dados da controller, action Index
			axios.post("/api/Comissionamentos/Index", this.Pagination).then((response) => {
				//Pega os dado do Grid
				this.GridData = response.data.Data;
				//Pega os dados da Paginação
				this.Pagination = response.data.Pagination;
				this.totalAReceber = this.GridData.reduce((sum, item) => sum + item.ValorFinal, 0);

				axios.get("/api/Listas/Advogados", this.Pagination).then((response) => {
					//Pega os dado do Grid
					this.ListaAdvogados = response.data;
				});
			});
		},
		ConverteMoedaBR(valor) {
			return new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(valor));
		},
		ConverteMoedaUS(valor) {
			return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(valor));
		},
		RowClass(item) {
			if (item.Ativo == false) {
				return "rowdisabled";
			}
		},
		FormataMoeda(valor, moeda = 1, zero = false) {
			if (valor == 0 && !zero) {
				return "";
			}
			var sPreMoeda = "R$ ";
			if (moeda == 2) {
				sPreMoeda = "U$ ";
			}
			return sPreMoeda.concat(
				parseFloat(valor)
					.toFixed(2)
					.replace(".", ",")
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
			);
		},
		CriaFiltroAdvogado() {
			const uniqueAdvogadosMap = new Map();
			// Iterar pelos itens e adicionar no Map
			this.GridData.forEach((item) => {
				const advogado = item.Advogado;
				// Adicionar no Map, a chave será o Id do advogado
				uniqueAdvogadosMap.set(advogado.Id, advogado);
			});

			// Converter o Map para uma lista de objetos
			this.ListaAdvogados = Array.from(uniqueAdvogadosMap.values());
		},
		// RowSelect para edição
		RowSelectClick: function (item, row) {
			// this.$router.push("/Faturamento/Edit/".concat(item.Id));
		},
	},
	mounted: function () {
		this.Index();
	},
};
</script>
