<template>
	<div>
		<v-card>
			<v-card-text class="pl-1 pr-1">
				<v-row dense>
					<v-col cols="12" offset="2">
						<v-row dense>
							<v-col cols="1" offset="1">
								<v-text-field :style="{ background: '#EEEEEE' }" outlined dense hide-details label="Id" v-model="Model.Id" readonly></v-text-field>
							</v-col>
							<v-col cols="1" offset="0">
								<v-text-field :style="{ background: '#EEEEEE' }" readonly outlined dense hide-details label="Referência" :value="Model.ReferenciaNumero + ' / ' + Model.ReferenciaAno"></v-text-field>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="6" offset="1">
								<v-text-field :style="{ background: '#EEEEEE' }" readonly outlined dense hide-details label="Cliente" v-model="Model.Cliente.Nome"></v-text-field>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="6" offset="1">
								<v-text-field :style="{ background: '#EEEEEE' }" readonly outlined dense hide-details label="Caso" v-model="Model.Caso.Nome"></v-text-field>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="2" offset="1">
								<v-currency-field :style="{ background: '#EEEEEE' }" readonly :prefix="moeda(Model.MoedaFaturamento)" outlined dense hide-details label="Valor Lançamentos" v-model="Model.ValorLancamentos" locale="en-US" :precision="{ min: 2, max: 2 }"></v-currency-field>
							</v-col>
							<v-col cols="2">
								<v-currency-field :style="{ background: '#EEEEEE' }" readonly :prefix="moeda(Model.MoedaFaturamento)" outlined dense hide-details label="Valor Despesas" v-model="Model.ValorDespesas" locale="en-US" :precision="{ min: 2, max: 2 }"></v-currency-field>
							</v-col>
							<v-col cols="2">
								<v-currency-field :style="{ background: '#EEEEEE' }" readonly :prefix="moeda(Model.MoedaFaturamento)" outlined dense hide-details label="Valor Faturado Original" v-model="Model.ValorFaturado" locale="en-US" :precision="{ min: 2, max: 2 }"></v-currency-field>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="2" offset="1">
								<v-text-field outlined dense hide-details label="Vencimento" v-model="sDataVencimento" v-mask="'##/##/####'"></v-text-field>
							</v-col>
							<v-col cols="2" offset="0">
								<v-currency-field :style="{ background: '#EEEEEE' }" readonly :prefix="moeda(1)" outlined dense hide-details label="Cotação Dólar" v-model="sCotacaoDolar"></v-currency-field>
							</v-col>
							<v-col cols="2" offset="0">
								<v-currency-field :style="{ background: '#EEEEEE' }" readonly :prefix="moeda(1)" outlined dense hide-details label="Valor Cobrado Original" v-model="sValorCobrado"></v-currency-field>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="1" offset="6">
								<v-text-field outlined dense hide-details autofocus label="Desconto" @focus="CalcularDesconto" @input="CalcularDesconto" v-model="sValorDesconto" v-mask="'##,##'" suffix="%"></v-text-field>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="2" offset="1">
								<v-currency-field :style="{ background: '#EEEEEE' }" outlined dense hide-details readonly v-model="sValorLancamentosComDesconto" label="Valor Lançamentos" locale="en-US" prefix="U$ " :precision="{ min: 2, max: 2 }"></v-currency-field>
							</v-col>
							<v-col cols="2">
								<v-currency-field :style="{ background: '#EEEEEE' }" outlined dense hide-details readonly v-model="Model.ValorDespesas" label="Valor Despesas" locale="en-US" prefix="U$ " :precision="{ min: 2, max: 2 }" style="text-align: right"></v-currency-field>
							</v-col>
							<v-col cols="2">
								<!-- <v-text-field outlined dense hide-details readonly prefix="R$ " v-model="sValorComDesconto" label="Valor Cobrado com Desconto"></v-text-field> -->
								<v-currency-field :style="{ background: '#EEEEEE' }" outlined dense hide-details readonly v-model.number.lazy="sValorComDesconto" label="Valor Despesas" locale="pt-BR" prefix="R$ " :precision="{ min: 2, max: 2 }"></v-currency-field>
							</v-col>
						</v-row>
						<v-row dense class="pt-16">
							<v-col col="12" md="8" lg="8" offset="0" offset-md="0" offset-lg="0">
								<v-row>
									<v-col>
										<v-btn block @click="Cancelar()" color="warning">Cancelar</v-btn>
									</v-col>
									<v-spacer></v-spacer>
									<v-col>
										<v-btn block @click="ShowConfirmaSalvar = true" color="primary">{{ Model.Id == 0 ? "Incluir" : "Salvar" }}</v-btn>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<confirm Msg="Excluir?" :Show="ShowConfirmaExcluir" v-on:confirm="Delete(), (ShowConfirmaExcluir = false)" v-on:cancel="ShowConfirmaExcluir = false"></confirm>
		<confirm Msg="Salvar?" :Show="ShowConfirmaSalvar" v-on:confirm="Save(), (ShowConfirmaSalvar = false)" v-on:cancel="ShowConfirmaSalvar = false"></confirm>
	</div>
</template>

<script>
import axios from "axios";
import moment, { relativeTimeThreshold } from "moment";
import { VMoney } from "v-money";

export default {
	name: "Edit",
	directives: { money: VMoney },
	data() {
		return {
			ShowDialog: true,
			sDataVencimento: "",
			sCotacaoDolar: "",
			sValorComDesconto: 0,
			sValorComDesconto2: 0,
			sValorLancamentosComDesconto: 0,
			sValorDesconto: 0,
			sValorFaturado: 0,
			Model: {},
			ShowConfirmaExcluir: false,
			ShowConfirmaSalvar: false,
			Lancamentos: [],
			money: {
				decimal: ",",
				thousands: ".",
				prefix: "R$ ",
				// suffix: " #",
				precision: 2,
				// masked: false /* doesn't work with directive */,
			},
		};
	},
	methods: {
		formatBR(value) {
			var numericValue = value.toString().replace(/[^\d.,]/g, "");
			numericValue = numericValue.replace(",", ".");
			const number = parseFloat(numericValue) || 0;
			const formatter = new Intl.NumberFormat("pt-BR", {
				// style: "currency",
				// currency: "BRL",
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});
			return formatter.format(number);
		},
		formatUS(value) {
			var numericValue = value.toString().replace(/[^\d.,]/g, "");
			numericValue = numericValue.replace(",", ".");
			const number = parseFloat(numericValue) || 0;
			const formatter = new Intl.NumberFormat("en-US", {
				// style: "currency",
				// currency: "USD",
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});
			return formatter.format(number);
		},
		formatData(date, formato) {
			if (formato == "US") {
				return moment(date,"DD/MM/YYYY").format("YYYY-MM-DD");
			} else {
				return moment(date,"YYYY-MM-DD").format("DD/MM/YYYY");
			}
		},
		CalcularDesconto(sPerc) {
			var sPorcento = Number.parseFloat(("0" + sPerc.toString()).replace(",", "."));
			this.sValorLancamentosComDesconto = this.AplicaDesconto(this.Model.ValorLancamentos, sPorcento);
			this.sValorComDesconto = (this.sValorLancamentosComDesconto + this.Model.ValorDespesas) * this.Model.CotacaoDolar;
			console.log(this.sValorComDesconto, typeof this.sValorComDesconto);
		},
		AplicaDesconto(valor, desconto) {
			console.log(valor, desconto);
			return valor - (valor * desconto) / 100;
		},
		moeda(tipo) {
			if (tipo == 1) {
				return "R$ ";
			} else if (tipo == 2) {
				return "U$ ";
			}
		},
		Cancelar() {
			this.$router.push("/Faturamento/Index").catch(() => {});
		},
		Edit(Id) {
			axios.get("/api/Faturamento/Edit/".concat(Id)).then((response) => {
				//Pega os dados do response e joga no objeto Model
				this.Model = response.data.Data;
				this.sValorDesconto = this.Model.ValorDesconto;
				// console.log(this.sValorComDesconto, typeof(this.sValorComDesconto), "edit");
				this.sDataVencimento = moment(this.Model.DataVencimento).format("DD/MM/YYYY");
				this.sCotacaoDolar = this.ConverteMoedaBR(this.Model.CotacaoDolar);
				this.ValorFaturado = this.Model.ValorDespesas + this.Model.ValorLancamentos;
				this.sValorCobrado = this.ConverteMoedaBR(this.ValorFaturado * this.Model.CotacaoDolar);
				// this.CalcularDesconto(this.sValorDesconto);
			});
		},
		Save() {
			this.Model.ValorDesconto = this.sValorDesconto;
			this.Model.DataVencimento = this.formatData(this.sDataVencimento, "US");
			this.Model.sValorCobrado = this.sValorCobrado;
			axios({ method: "post", data: this.Model, url: "/api/Faturamento/Save" })
				.then((response) => {
					//this.$toast.success("Alterações salvas.", { timeout: 4000 });
					//this.$toast.success("Regerando Faturamento, Aguarde...", { timeout: 3000 });
					// var item = this.Model;
					// axios.get("/api/Faturamento/ReEmitirFaturamento/".concat(item.IdCaso).concat("/").concat(item.IdCliente).concat("/").concat(item.Id)).then((response) => {
					// 	// debugger;
					// 	var sJsonRetorno = response.data;
					// 	if (sJsonRetorno.codigo_status == 0) {
					// 		this.$toast.success("Faturamento alterado.", { timeout: 3000 });
					// 	} else {
					// 		this.$toast.error("Processamento com erro,\n fatura já emitida ou sem contato com o Omie", { timeout: 5000 });
					// 	}
					// 	this.$router.push("/Faturamento/Index").catch(() => {});
					// });
					this.$router.push("/Faturamento/Index").catch(() => {});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		GeraFaturamento(item) {},
		Delete() {
			axios({ method: "delete", data: this.Model, url: "/api/Faturamento/Delete" })
				.then((response) => {
					this.Index();
				})
				.catch((error) => {
					//
				});
		},
		Index() {
			this.$router.push("/Faturamento/Index");
		},
		GetListas() {
			// const instance = axios.create();
			// const GetLancamentos = instance.get("/api/Listas/Faturamento");
			// axios.all([GetLancamentos])
			// 	.then(
			// 		axios.spread((...responses) => {
			// 			responses[0].data.forEach((item) => {
			// 				this.Lancamentos.push(item);
			// 			});
			// 		})
			// 	)
			// 	.catch((errors) => {});
		},
		ConverteMoedaBR(valor) {
			return new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(valor));
		},
		ConverteMoedaUS(valor) {
			return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(valor));
		},
	},
	mounted: function () {
		//Pega a Id enviada pela rota
		let Id = this.$route.params.id;
		this.GetListas();

		if (Id != null) {
			//se for null, volta para o Index
			this.Edit(Id);
		} else {
			//this.$router.push("/Cadastro/Lancamentos/Index");
			this.Edit(this.idLancamento);
		}
	},
};
</script>
