<template>
	<div class="bordaappbar">
		<v-data-table class="elevation-1 row-pointer" mobile-breakpoint="1" :headers="Header" :items="GridData" :item-class="RowClass" :sort-desc.sync="Pagination.sortDesc" item-key="Id" :options.sync="Pagination" :server-items-length="Pagination.itemsLength" :footer-props="{ itemsPerPageOptions: RowsPerPageItems }" v-on:update:page="Index()" v-on:update:items-per-page="Index()" v-on:update:sort-by="Index()" v-on:update:sort-desc="Index()">
			<template v-slot:top>
				<v-toolbar dense flat class="mt-5">
					<v-col cols="6">
						<v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="Procurar" v-model="Pagination.Filtro.Busca" @change="Index()"></v-text-field>
					</v-col>
					<v-col>
						<v-switch hide-details dense label="Mostrar Inativos" color="primary" v-model="Pagination.Filtro.Inativos" :false-value="false" :true-value="true" @change="Index()"></v-switch>
					</v-col>
					<v-spacer></v-spacer>
					<v-btn to="/Sistema/GruposController/Edit/0" color="primary">
						Nova
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</v-toolbar>

				<br />
			</template>

			<template v-slot:[`item.Action`]="{ item }">
				<v-icon @click="Edit(item.Id)" color="teal">mdi-pencil</v-icon>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import axios from "axios";

export default {
	name: "SystemGroup",
	data() {
		return {
			GridData: [],
			Header: [
				{ text: "Id", value: "Id", sortable: false },
				{ text: "Nome", value: "Nome", sortable: false },
				{ text: "", value: "Action", sortable: false },
			],
			RowsPerPageItems: [5, 10, 20, 30, 40, 50, 100, 200],
			Pagination: {
				page: 1,
				itemsPerPage: 100,
				pageCount: 1,
				itemsLength: 0,
				sortBy: ["Nome"],
				sortDesc: [false],
				Filtro: {
					Busca: "",
					Inativos: false,
					FiltroGrupo: "C",
				},
			},
			showDialogCadastro: false,
		};
	},
	components: {},
	methods: {
		Edit(Id) {
			this.$router.push("/Sistema/GruposController/Edit/".concat(Id));
		},
		Index() {
			axios.post("/api/Sistema/Grupos/Index", this.Pagination).then((response) => {
				this.GridData = response.data.Data;
				this.Pagination = response.data.Pagination;
			});
		},
		RowClass(item) {
			if (item.FlagAtivo == false) {
				return "rowdisabled";
			}
		},
	},
	mounted: function () {
		this.Index();
	},
};
</script>
