import axios from "axios";

export default {
	//
	// INICIO CONTROLE DE ACESSO
	// incluir nas variáveis: IsPermitted: 0,
	// e mounted(): this.LoadPermission();
	// uso:  v-if="CheckPermission('E')" ou readonly="CheckPermission('E')"
	AllowPermitted(out = 0) {
		if (out == 0) return "pointer-events: auto";
		else return true;
	},
	DeletePermitted(permit, out = 0) {
		// v-if="DeletePermitted()"
		if (permit >= 4) {
			if (out == 0) return "pointer-events: auto";
			// clicável zero
			else return true; // visível
		}
		if (out == 0) return "pointer-events: none";
		// clicável zero
		else return false; // visível zero
	},
	EditPermitted(permit, out = 0) {
		// :style="EditPermitted()"
		if (permit > 1) {
			if (out == 0) return "pointer-events: auto;";
			// clicável
			else return true; // visível
		}
		if (out == 0) return "pointer-events: none;";
		// clicável zero
		else return false; // visível zero
	},
	LoadPermission() {
		const permit = async () => {
			let res = await axios.get("/api/Listas/GetAcesso");
			let { data } = res.data; //or res
			return data;
		};
		return permit;
	},
	//
	// FIM CONTROLE DE ACESSO
	//

	ConverteMoedaBR(valor) {
		return new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(valor));
	},
	ConverteMoedaUS(valor) {
		return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(valor));
	},
	FormataMoeda(valor, moeda = 1, zero = false) {
		if (valor == 0 && !zero) {
			return "";
		}
		var sPreMoeda = "R$ ";
		if (moeda == 2) {
			sPreMoeda = "U$ ";
		}
		return sPreMoeda.concat(
			parseFloat(valor)
				.toFixed(2)
				.replace(".", ",")
				.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
		);
	},
	formatDateForMySQL(sData) {
		// Divide a data no formato brasileiro em dia, mês e ano
		const [day, month, year] = sData.split("/");

		// Retorna a data no formato MySQL
		return `${year}-${month}-${day}`;
	},
};
