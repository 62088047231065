<style scoped>
.financial-dashboard {
	padding: 20px;
}
.chart-container {
	margin-top: 20px;
	height: 400px;
}
</style>
<!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.9.3/Chart.min.js"></script> -->

<template>
	<!-- <v-container> -->
	<div>
		<v-row>
			<v-col cols="12" class="d-flex align-end flex-column">
				<v-switch v-model="sMoedaUS" @change="DashBoard()" :label="`Moeda: ${sMoedaUS == 2 ? 'USD' : 'BRL'}`" :false-value="1" :true-value="2" hide-details></v-switch>
			</v-col>
		</v-row>
		<!-- Valores a Receber por Data -->
		<v-row>
			<v-col cols="3">
				<v-card>
					<v-card color="primary" class="mx-auto pa-5" id="DolarHoje">
						<v-card-title id="DolarHoje">
							<div>
								<!-- <div class="text-overline mb-1">kkk</div> -->
								<div class="text-h5 mb-1 white--text">Cotação Dólar Venda</div>
								<div class="text-h5 mb-1 yellow--text font-weight-bold">R$ {{ this.DolarHoje }}</div>
							</div>
						</v-card-title>
						<!-- <v-card-actions><v-btn>Button</v-btn></v-card-actions> -->
					</v-card>
				</v-card>
			</v-col>

			<v-col cols="3">
				<v-card>
					<v-card color="primary" class="mx-auto pa-5" id="Importacao">
						<v-card-title id="Importacao">
							<div>
								<!-- <div class="text-overline mb-1">kkk</div> -->
								<div class="text-h5 mb-1 white--text">Última Importação</div>
								<div class="text-h5 mb-1 yellow--text font-weight-bold">{{ this.Model.UltimaImportacao | dateBR }}</div>
							</div>
						</v-card-title>
						<!-- <v-card-actions><v-btn>Button</v-btn></v-card-actions> -->
					</v-card>
				</v-card>
			</v-col>

			<v-col cols="3">
				<v-card>
					<v-card color="primary" class="mx-auto pa-5" id="AReceber">
						<v-card-title>
							<div>
								<!-- <div class="text-overline mb-1">kkk</div> -->
								<div class="text-h5 mb-1 white--text">Faturas a vencer</div>
								<div v-show="this.sMoedaUS == 2" class="text-h5 mb-1 yellow--text font-weight-bold">{{ this.Model.TotalValoresAReceber | currencyUS }}</div>
								<div v-show="this.sMoedaUS == 1" class="text-h5 mb-1 yellow--text font-weight-bold">{{ this.Model.TotalValoresAReceber | currencyBR }}</div>
							</div>
						</v-card-title>
						<!-- <v-card-actions><v-btn>Button</v-btn></v-card-actions> -->
					</v-card>
				</v-card>
			</v-col>

			<v-col cols="3">
				<v-card>
					<v-card color="primary" class="mx-auto pa-5" id="Atrasados">
						<v-card-title>
							<div>
								<!-- <div class="text-overline mb-1">kkk</div> -->
								<div class="text-h5 mb-1 white--text">Faturas em atraso</div>
								<div v-show="this.sMoedaUS == 2" class="text-h5 mb-1 yellow--text font-weight-bold">{{ this.Model.TotalValoresAtrasados | currencyUS }}</div>
								<div v-show="this.sMoedaUS == 1" class="text-h5 mb-1 yellow--text font-weight-bold">{{ this.Model.TotalValoresAtrasados | currencyBR }}</div>
							</div>
						</v-card-title>
						<!-- <v-card-actions><v-btn>Button</v-btn></v-card-actions> -->
					</v-card>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col col="6">
				<template>
					<v-card>
						<v-app-bar color="primary" dense>
							<v-toolbar-title class="white--text">Faturas a vencer por cliente</v-toolbar-title>
						</v-app-bar>
						<v-card-text class="bordaappbar">
							<canvas id="Chart1"></canvas>
						</v-card-text>
					</v-card>
				</template>
			</v-col>

			<v-col col="6">
				<v-card>
					<v-app-bar color="primary" dense>
						<v-toolbar-title class="white--text">Fluxo de Recebimentos por data</v-toolbar-title>
					</v-app-bar>
					<v-card-text class="bordaappbar">
						<canvas id="Chart2"></canvas>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col col="6">
				<v-card>
					<v-app-bar color="primary" dense>
						<v-toolbar-title class="white--text">Faturas a vencer por cliente</v-toolbar-title>
					</v-app-bar>
					<v-card-text class="bordaappbar">
						<v-data-table class="elevation-1 row-pointer" :headers="HeaderAFaturar" mobile-breakpoint="1" :items="Model2" :hide-default-footer="true">
							<template v-slot:[`item.DataVencimento`]="{ item }">
								<span class="text-no-wrap">{{ item.DataVencimento | dateBR }}</span>
							</template>
							<template v-slot:[`item.ValoresAReceber`]="{ item }">
								<span v-show="sMoedaUS == 2" class="text-no-wrap">{{ item.ValoresAReceber | currencyUS }}</span>
								<span v-show="sMoedaUS == 1" class="text-no-wrap">{{ item.ValoresAReceber | currencyBR }}</span>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col col="6">
				<v-card>
					<v-app-bar color="primary" dense>
						<v-toolbar-title class="white--text">Faturas em atraso por cliente</v-toolbar-title>
					</v-app-bar>
					<v-card-text class="bordaappbar">
						<v-data-table class="elevation-1 row-pointer" :headers="HeaderAtrasados" mobile-breakpoint="1" :items="Model3" :hide-default-footer="true">
							<template v-slot:[`item.DataVencimento`]="{ item }">
								<span class="text-no-wrap">{{ item.DataVencimento | dateBR }}</span>
							</template>
							<template v-slot:[`item.ValoresAFaturar`]="{ item }">
								<span v-show="sMoedaUS == 2" class="text-no-wrap">{{ item.ValoresAtrasados | currencyUS }}</span>
								<span v-show="sMoedaUS == 1" class="text-no-wrap">{{ item.ValoresAtrasados | currencyBR }}</span>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</div>
	<!-- </v-container> -->
</template>

<script>
import axios from "axios";
import moment, { relativeTimeThreshold } from "moment";
import { Line, Bar } from "vue-chartjs";
import { Chart, registerables, datalabels } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ColorHash from "color-hash";
Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default {
	name: "Home",
	data() {
		return {
			Model: [],
			Model2: [],
			Model3: [],
			sMoedaUS: 2,
			TotalModel2: 0,
			DolarHoje: 1,
			ChartX1: null,
			ChartX2: null,
			First: true,
			MostraGraph: false,
			valoresAFaturarPorClienteChart: null,
			valoresAReceberPorClienteChart: null,
			valoresAtrasadosPorDataChart: null,
			valoresAReceberPorDataChart: null,
			HeaderAFaturar: [
				// { text: "", value: "", align: "left", width: "100", sortable: false },
				{ text: "Cliente", value: "NomeCliente", align: "left", width: "40%", sortable: false },
				{ text: "Data Vencimento", value: "DataVencimento", align: "center", width: "30%", sortable: false },
				{ text: "Valor Faturado", value: "ValoresAReceber", align: "right", width: "30%", sortable: false },
				// { text: "", value: "", align: "left", width: "100", sortable: false },
			],
			HeaderAtrasados: [
				{ text: "Cliente", value: "NomeCliente", align: "left", width: "40%", sortable: false },
				{ text: "Data Vencimento", value: "DataVencimento", align: "center", width: "30%", sortable: false },
				{ text: "Valores a Receber", value: "ValoresAFaturar", align: "right", width: "30%", sortable: false },
				// { text: "", value: "", align: "left", width: "10%", sortable: false },
			],
			RowsPerPageItems: [5, 10, 20, 30, 40, 50, 100, 200],
			Pagination: {
				page: 1,
				itemsPerPage: 50,
				pageCount: 1,
				itemsLength: 0,
				sortBy: ["NomeCliente"],
				sortDesc: [false],
				Filtro: {
					Busca: "",
				},
			},
		};
	},

	components: {},
	methods: {
		shuffle(arr) {
			return Array(arr.length)
				.fill(null)
				.map((_, i) => [Math.random(), i])
				.sort(([a], [b]) => a - b)
				.map(([, i]) => arr[i]);
		},
		PopulaDados() {
			axios.get("/api/Faturamento/Dashboard/".concat(this.sMoedaUS)).then((response) => {
				this.Model = response.data;
				this.Model2 = response.data.ValoresAReceberPorCliente;
				// this.TotalModel2 = this.Model2.reduce((a, b) => a + (b["ValoresAFaturar"] || 0), 0);
				this.Model3 = response.data.ValoresAtrasadosPorCliente;
			});
		},
		DashBoard() {
			axios.get("/api/Faturamento/Dashboard/".concat(this.sMoedaUS))
				.then((response) => {
					this.Model = response.data;
					this.Model2 = response.data.ValoresAReceberPorCliente;
					// this.TotalModel2 = this.Model2.reduce((a, b) => a + (b["ValoresAFaturar"] || 0), 0);
					this.Model3 = response.data.ValoresAtrasadosPorCliente;
					// debugger;
					this.criaGrafico1(
						"Chart1",
						"bar",
						this.Model.ValoresAReceberPorCliente.map((item) => this.transformSentence(item.NomeCliente)),
						this.Model.ValoresAReceberPorCliente.map((item) => item.ValoresAReceber),
						this.sMoedaUS == 1 ? "R$" : "USD"
					);

					this.criaGrafico2(
						"Chart2",
						"bar",
						this.Model.ValoresAReceberPorData.map((item) => this.formatData(item.DataVencimento)),
						this.Model.ValoresAReceberPorData.map((item) => item.ValoresAReceber),
						// this.CumulativeSum(this.Model.ValoresAReceberPorData.map((item) => item.ValoresAReceber)),
						this.sMoedaUS == 1 ? "R$" : "USD"
					);
				})
				.catch((error) => {
					// console.log(error);
				});
		},
		criaGrafico1(ctx2, tipo, labels, data, title) {
			var colorHash = new ColorHash();
			//var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
			var bgColor = [];
			for (var i = 0; i < labels.length; i++) {
				bgColor.push(colorHash.hex(labels[i]));
			}
			var ctx = document.getElementById(ctx2);
			if (this.ChartX1 != null) this.ChartX1.destroy();
			this.ChartX1 = new Chart(ctx, {
				type: tipo,
				data: {
					labels: labels,
					datasets: [
						{
							data: data,
							// type: 'Line',
							backgroundColor: bgColor,
						},
					],
				},
				options: {
					plugins: {
						title: {
							display: true,
							text: title,
							position: "top",
							align: "end",
							font: {
								size: 14,
								forecolor: "black",
							},
						},
						legend: {
							display: false,
							position: "right",
						},
						legend: {
							display: false,
						},
						tooltips: {
							enabled: true,
						},
						scales: {
							// xAxes: [{ ticks: { min: 40, max: 160 } }],
							// yAxes: [{ ticks: { min: 6, max: 16 } }],
						},
						datalabels: {
							anchor: "end",
							align: "top",
							color: "black",
							// formatter: Math.round,
							font: {
								// weight: "bold",
							},
							formatter: function (value, context) {
								var sPreMoeda = "";
								var sTitle = context.chart.config._config.options.plugins.title.text;
								if (sTitle == "R$") {
									sPreMoeda = "R$ ".concat(
										parseFloat(value)
											.toFixed(2)
											.replace(".", ",")
											.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
											.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
									);
								} else if (sTitle == "USD") {
									sPreMoeda = "U$ ".concat(
										parseFloat(value)
											.toFixed(2)
											// .replace(".", ",")
											.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
											.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
									);
								}
								return sPreMoeda;
							},
						},
					},
				},
			});
		},
		criaGrafico2(ctx2, tipo, labels, data, title) {
			var colorHash = new ColorHash();
			//var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
			var bgColor = [];
			for (var i = 0; i < labels.length; i++) {
				bgColor.push(colorHash.hex(labels[i]));
			}
			var ctx = document.getElementById(ctx2);
			if (this.ChartX2 != null) this.ChartX2.destroy();
			this.ChartX2 = new Chart(ctx, {
				type: tipo,
				data: {
					labels: labels,
					datasets: [
						{
							data: data,
							// type: 'Line',
							backgroundColor: bgColor,
						},
					],
				},
				options: {
					plugins: {
						title: {
							display: true,
							text: title,
							position: "top",
							align: "end",
							font: {
								size: 14,
								forecolor: "black",
							},
						},
						legend: {
							display: false,
							position: "right",
						},
						tooltips: {
							enabled: true,
						},
						scales: {
							// xAxes: [{ ticks: { min: 40, max: 160 } }],
							// yAxes: [{ ticks: { min: 6, max: 16 } }],
						},
						datalabels: {
							anchor: "end",
							align: "top",
							color: "black",
							// formatter: Math.round,
							font: {
								// weight: "bold",
							},
							formatter: function (value, context) {
								var sPreMoeda = "";
								var sTitle = context.chart.config._config.options.plugins.title.text;
								if (sTitle == "R$") {
									sPreMoeda = "R$ ".concat(
										parseFloat(value)
											.toFixed(2)
											.replace(".", ",")
											.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
											.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
									);
								} else if (sTitle == "USD") {
									sPreMoeda = "U$ ".concat(
										parseFloat(value)
											.toFixed(2)
											// .replace(".", ",")
											.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
											.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
									);
								}
								return sPreMoeda;
							},
						},
					},
				},
			});
		},
		formatData(date) {
			return moment(date).format("DD/MM/YYYY");
		},
		formatCurrency(value) {
			return value;
		},
		CumulativeSum(value) {
			var new_array = [];
			value.reduce(function (a, b, i) {
				return (new_array[i] = a + b);
			}, 0);
			return new_array;
		},
		transformSentence(sentence) {
			return sentence
				.split(" ") // Divide a frase em um array de palavras
				.map((word) => word.slice(0, 5)) // Deixa apenas os 4 primeiros caracteres de cada palavra
				.join(" ")
				.substring(0, 15); // Junta as palavras de volta em uma frase
		},
		async GetDolarHoje() {
			await axios.get("/api/Listas/GetDolarHoje").then((response) => {
				console.log(response.data);
				this.DolarHoje = response.data;
				return response.data;
			});
		},
		FormataMoeda(valor, moeda) {
			var sPreMoeda = "";
			if (moeda == 1) {
				sPreMoeda = "R$ ";
			} else if (moeda == 2) {
				sPreMoeda = "U$ ";
			}
			return sPreMoeda.concat(
				parseFloat(valor)
					.toFixed(2)
					.replace(".", ",")
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
			);
		},
		async sleep(seconds) {
			return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
		},
	},
	created: function () {},
	mounted: function () {
		// this.Index();
		this.GetDolarHoje();
		// this.PopulaDados();
	},
	// funcao para renderizar o GraphJS após o DOM ser montado
	updated: function () {
		this.$nextTick(function () {
			if (this.First == true) {
				this.DashBoard();
				this.First = false;
			}
		});
	},
};
</script>
