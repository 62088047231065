<style scoped>
.wall-switch {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.switch-container {
	display: flex;
	align-items: center;
	gap: 16px; /* Espaçamento entre os elementos */
}

.labelleft {
	font-size: 16px;
	align-items: right;
	margin-top: 5px;
}
.labelright {
	font-size: 16px;
	align-items: left;
	margin-top: 5px;
}

.custom-switch {
	margin: 0;
	--v-switch-width: 50px;
	--v-switch-height: 28px;
}

/* Customização para manter a mesma cor */
.same-color-switch .v-input__control {
	background-color: #4caf50 !important; /* Cor do fundo do switch */
	border-color: #4caf50 !important; /* Cor da borda */
}

.same-color-switch .v-input__control:hover {
	background-color: #45a049 !important; /* Cor ao passar o mouse */
}

.same-color-switch .v-input--selection-controls__ripple {
	background-color: #4caf50 !important; /* Cor do efeito ripple */
}

.same-color-switch .v-input__control:before {
	background-color: #fff !important; /* Cor do botão deslizante */
}

.same-color-switch .v-input__control:after {
	background-color: #fff !important; /* Cor do botão deslizante */
}
</style>

<style scoped>
.json-display {
	display: flex;
	gap: 20px;
}

.json-column {
	flex: 1;
	padding: 10px;
	border: 1px solid #ddd;
	border-radius: 5px;
	background-color: #f9f9f9;
}

pre {
	overflow-x: auto;
	white-space: pre-wrap;
	word-wrap: break-word;
}
</style>
<template>
	<div class="bordaappbar">

		<v-data-table class="elevation-1 row-pointer" :headers="computedHeaders" mobile-breakpoint="1" :items="GridData" :item-class="RowClass" :sort-by.sync="Pagination.sortBy" :must-sort="true" :sort-desc.sync="Pagination.sortDesc" item-key="Id" :options.sync="Pagination" :server-items-length="Pagination.itemsLength" :footer-props="{ itemsPerPageOptions: RowsPerPageItems }" v-on:update:page="Index()" v-on:update:items-per-page="Index()" v-on:update:sort-by="Index()" v-on:update:sort-desc="Index()">
			<template v-slot:top>
				<v-toolbar dense flat class="mt-5">
					<v-col cols="2">
						<v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="Procurar" v-model="Pagination.Filtro.Busca" v-on:keyup.enter="Index()"></v-text-field>
					</v-col>

					<v-col cols="2">
						<dtPicker
							label="Data Inicial"
							v-model="DataInicio"
							v-on:click="
								Index();
								ckAtraso = false;
							"
						></dtPicker>
					</v-col>

					<v-col cols="2">
						<dtPicker
							label="Data Final"
							v-model="DataFim"
							v-on:click="
								Index();
								ckAtraso = false;
							"
						></dtPicker>
					</v-col>

					<v-col cols="1" class="mt-7">
						<v-checkbox v-model="Pagination.Filtro.EmAtraso" @click="Index()" value="true" label="Em atraso"></v-checkbox>
					</v-col>

					<v-col cols="2">
						<div class="wall-switch">
							<div class="switch-container">
								<span class="labelleft">Quitados</span>
								<v-switch v-model="Pagination.Filtro.EmAberto" dense inset class="custom-switch same-color-switch" false-value="false" true-value="true" hide-details @click="Pagination.Filtro.EmAtraso='false';Index()"></v-switch>
								<span class="labelright">Em aberto</span>
							</div>
						</div>
					</v-col>

					<v-col cols="2">
						<v-btn color="primary" @click="Index()">Filtrar</v-btn>
					</v-col>
				</v-toolbar>
				<br />
			</template>

			<template v-slot:[`item.Data`]="{ item }">
				<span class="text-no-wrap">
					{{ item.Data | dateBR }}
				</span>
			</template>

			<template v-slot:[`item.IdentificacaoProcesso`]="{ item }">
				<span class="text-no-wrap" v-if="item.DataVencimento >= DataHoje() || item.ValorPago > 0">
					{{ item.Cliente.Nome }}
					<br />
					&nbsp;&nbsp;&nbsp;&nbsp;{{ item.Caso.Nome }}
				</span>
				<span class="text-no-wrap" style="color: red" v-else>
					{{ item.Cliente.Nome }}
					<br />
					&nbsp;&nbsp;&nbsp;&nbsp;{{ item.Caso.Nome }}
				</span>
			</template>
			<template v-slot:[`item.DataVencimento`]="{ item }">
				<span class="text-no-wrap" v-if="item.DataVencimento >= DataHoje() || item.ValorPago > 0">
					{{ item.DataVencimento | dateBR }}
				</span>
				<span class="text-no-wrap" style="color: red" v-else>
					<b>{{ item.DataVencimento | dateBR }}</b>
				</span>
			</template>

			<template v-slot:[`item.DataPagamento`]="{ item }">
				<span class="text-no-wrap" v-if="item.ValorPago > 0">
					{{ item.DataPagamento | dateBR }}
				</span>
				<span v-else>
					<v-icon @click="BaixaClick(item)" color="primary" title="Efetuar Baixa do Pagamento">mdi-account-cash</v-icon>
				</span>
			</template>

			<template v-slot:[`item.IdOmie`]="{ item }">
				<span class="text-no-wrap" align="center">
					<v-img v-if="item.IdOmie > 0" src="../../assets/omie.png" width="50px"></v-img>
					<v-btn v-else @click="GerarContasReceber(item.Id)" color="primary" title="Gerar Contas a Receber no Omnie"><v-icon>mdi-invoice-text-plus-outline</v-icon></v-btn>
				</span>
			</template>

			<template v-slot:[`item.ValorFaturado`]="{ item }">
				<span class="text-no-wrap" style="color: #ff5722" v-if="item.ValorDesconto > 0">
					{{ FormataMoeda(item.ValorFaturado, item.MoedaFaturamento) }} ({{ item.ValorDesconto }}%)
					<br />
				</span>
				<span class="text-no-wrap">
					{{ FormataMoeda(desconto(item.ValorFaturado, item.ValorDesconto), item.MoedaFaturamento) }}
				</span>
			</template>

			<template v-slot:[`item.Action5`]="{ item }">
				<span class="text-no-wrap" style="color: #ff5722" v-if="item.ValorDesconto > 0">
					{{ FormataMoeda(item.ValorFaturado * item.CotacaoDolar, 1) }} ({{ item.ValorDesconto }}%)
					<br />
				</span>
				<span class="text-no-wrap">
					{{ FormataMoeda(desconto(item.ValorFaturado, item.ValorDesconto) * item.CotacaoDolar, 1) }}
				</span>
			</template>

			<template v-slot:[`item.Action6`]="{ item }">
				<span class="text-no-wrap">{{ item.ReferenciaNumero }}-{{ item.ReferenciaAno }}</span>
			</template>

			<template v-slot:[`item.Action7`]="{ item }">
				<span class="text-no-wrap">
					<v-icon v-show="!IsDataValid(item.DataEnvioCliente)" @click="(ShowEnvioCliente = true), (itemFaturamento = item)" color="success" enabled="IdPreliminar>0" title="Enviar Faturamento ao CLiente">mdi-email-arrow-right-outline</v-icon>
					<span v-show="IsDataValid(item.DataEnvioCliente)">{{ item.DataEnvioCliente | dateBR }}</span>
				</span>
			</template>

			<template v-slot:[`item.MoedaFaturamento`]="{ item }">
				<span class="text-no-wrap">
					<span v-if="item.MoedaFaturamento == 1">R$</span>
					<span v-if="item.MoedaFaturamento == 2">U$</span>
				</span>
			</template>

			<template v-slot:[`item.ValorPago`]="{ item }">
				<span class="text-no-wrap" style="color: green" v-if="item.ValorPago > 0">
					<b>{{ FormataMoeda(item.ValorPago, 1) }}</b>
				</span>
				<span v-else-if="item.DataVencimento < DataHoje()">
					<v-icon color="error" title="Em Atraso">mdi-cancel</v-icon>
				</span>
				<span v-else>
					<v-icon color="#42A5F5" title="Aguardando Pagamento">mdi-cancel</v-icon>
				</span>
			</template>

			<template v-slot:[`item.Action3`]="{ item }">
				<div align="center">
					<v-icon @click="DownloadFaturamento(item)" color="primary" enabled="IdPreliminar>0" title="Download do Faturamento/ND">mdi-download</v-icon>
				</div>
			</template>

			<template v-slot:[`item.Action2`]="{ item }">
				<div align="center">
					<v-icon @click="(ShowExcluiFaturamento = true), (itemFaturamento = item)" color="error" title="Excluir Faturamento">mdi-trash-can</v-icon>
				</div>
			</template>

			<template v-slot:[`item.Action`]="{ item }">
				<div align="center">
					<v-icon @click="Edit(item.Id)" color="teal" title="Editar Faturamento">mdi-book-edit</v-icon>
				</div>
			</template>

			<template v-slot:[`item.Action8`]="{ item }">
				<span v-if="item.DataComissionamento == null && item.ValorPago > 0">
					<v-btn @click="CalculaComissao(item)" color="primary" title="Calcular Comissões">Comissões</v-btn>
				</span>
				<span v-if="item.ValorPago > 0">
					{{ item.DataComissionamento | dateBR }}
				</span>
			</template>
		</v-data-table>

		<!-- DIALOG DE BAIXA DE PAGAMENTO -->
		<v-dialog v-model="DialogBaixa" width="800px">
			<v-card>
				<v-app-bar color="primary">
					<v-toolbar-title class="white--text">Baixa de Fatura</v-toolbar-title>
				</v-app-bar>
				<v-card-text>
					<v-container class="pt-10">
						<v-row>
							<v-col cols="12" lg="6" xl="6">
								<dtPicker label="Data Pagamento" v-model="editedItem.DataPagamento"></dtPicker>
							</v-col>
							<v-col cols="12" lg="5" xl="5">
								<v-text-field hide-details dense outlined readonly label="Valor Faturado" v-model="ValorFaturado"></v-text-field>
							</v-col>
							<v-col cols="1"  class="d-flex align-center">
								<v-icon color="success" @click="CopiarValor(editedItem.ValorFaturado, editedItem.ValorPago)" title="Copiar valor">mdi-download-multiple-outline</v-icon>								
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
								<v-autocomplete return-object :items="ListaBancos" item-text="descricao" item-value="nCodCC" v-model="selListaBancos" label="Conta-Corrente de Depósito" dense outlined hide-details></v-autocomplete>
							</v-col>
							<v-col cols="12" lg="6" xl="6">
								<v-currency-field :key="forceRerenderKey" outlined dense hide-details :currency="'BRL'" :locale="'pt-BR'" label="Valor Pago" v-model.number="editedItem.ValorPago"></v-currency-field>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions class="pt-10 pb-10">
					<v-spacer></v-spacer>
					<v-btn x-large color="error" @click="DialogBaixa = false">Cancelar</v-btn>
					<v-btn x-large color="primary" @click="ShowConfirmaBaixa = true">OK</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<confirm Msg="Confirma pagamento e Baixa da fatura ?" :Show="ShowConfirmaBaixa" v-on:confirm="BaixaSave(), (ShowConfirmaBaixa = false)" v-on:cancel="(ShowConfirmaBaixa = false), (DialogBaixa = false)"></confirm>

		<confirm Msg="Confirma a exclusão Faturamento ?" :Show="ShowExcluiFaturamento" v-on:confirm="ExcluirFaturamento(itemFaturamento), (ShowExcluiFaturamento = false)" v-on:cancel="ShowExcluiFaturamento = false"></confirm>

		<confirm Msg="Confirma o envio ao cliente ?" :Show="ShowEnvioCliente" v-on:confirm="EnvioFaturamento(itemFaturamento), (ShowEnvioCliente = false)" v-on:cancel="ShowEnvioCliente = false"></confirm>
	</div>
</template>

<script>
import axios from "axios";
import moment, { relativeTimeThreshold } from "moment";

export default {
	name: "Faturamento",
	data() {
		return {
			SohUmPorra: [],
			GridData: [],
			DataInicio: null,
			DataFim: null,
			ListaBancos: [],
			selListaBancos: null,
			totalAReceber: 0,
			totalLancamentos: 0,
			DialogBaixa: false,
			editedItem: { ValorPago: 0 },
			editedId: 0,
			ValorFaturado: 0,
			editedDataPagamento: null,
			editedValorPago: 0,
			itemFaturamento: [],
			ShowExcluiFaturamento: false,
			ShowEnvioCliente: false,
			ShowConfirmaBaixa: false,
			forceRerenderKey: 0,
			LabelFiltro: "Filtrar Pagos",
			Header: [
				{ text: "Id", value: "Id", width: "70", align: "center", sortable: false },
				{ text: "Referência", value: "Action6", sortable: false },
				{ text: "Data", value: "Data", align: "center", sortable: false },
				{ text: "Cliente/Caso", value: "IdentificacaoProcesso", width: "20%", sortable: false },
				{ text: "Valor Faturado", value: "ValorFaturado", align: "center", sortable: false },
				{ text: "Envio ao Cliente", value: "Action7", align: "center", sortable: false },
				{ text: "Omie", value: "IdOmie", align: "center", sortable: false },
				{ text: "Valor Cobrado", value: "Action5", align: "center", sortable: false },
				{ text: "Vencimento", value: "DataVencimento", align: "center", sortable: false },
				{ text: "Pagamento", value: "DataPagamento", align: "center", sortable: false },
				{ text: "Valor Pago", value: "ValorPago", align: "center", sortable: false },
				{ text: "Comissão", value: "Action8", align: "center", sortable: false },
				{ text: "Download", value: "Action3", align: "center", sortable: false },
				{ text: "Excluir", value: "Action2", align: "center", sortable: false },
				{ text: "Editar", value: "Action", align: "center", sortable: false },
				{ text: "", value: "Action4", align: "center", sortable: false },
			],
			RowsPerPageItems: [5, 10, 20, 30, 40, 50, 100, 200],
			Pagination: {
				page: 1,
				itemsPerPage: 50,
				pageCount: 1,
				itemsLength: 0,
				sortBy: ["DataVencimento", "Id"],
				sortDesc: [true],
				Filtro: {
					Busca: "",
					DataInicio: null,
					DataFim: null,
					EmAberto: "true",
					FlagFaturamento: "P",
					EmAtraso: "false",
				},
			},
		};
	},
	components: {},
	methods: {
		CopiarValor(ValorFaturado, ValorPago) {
			this.editedItem.ValorPago = parseFloat(this.editedItem.ValorFaturado * this.editedItem.CotacaoDolar).toFixed(2);
		},
		BaixaClick(item) {
			this.$toast.success("Capturando lista de Bancos no Omie.", { timeout: 4000 });
			axios.get("/api/Faturamento/Edit/".concat(item.Id)).then((response) => {
				this.editedItem = response.data.Data;
				this.editedItem.DataPagamento = moment().format("YYYY-MM-DD");
				this.ValorFaturado = this.FormataMoeda(parseFloat(this.editedItem.ValorFaturado * this.editedItem.CotacaoDolar).toFixed(2), 1);
				this.DialogBaixa = true;
				this.selListaBancos = null;
				this.PegaBancos();
			});
		},

		BaixaSave() {
			if (this.selListaBancos == null) {
				this.$toast.error("Banco de Depósito deve ser selecionado.", { timeout: 4000 });
				return;
			}
			if (this.editedItem.ValorPago <= 0) {
				this.$toast.error("Valor pago deverá ser maior que zero.", { timeout: 4000 });
				return;
			}
			axios.get("/api/Faturamento/Edit/".concat(this.editedItem.Id)).then((response) => {
				var new_editedItem = response.data.Data;
				new_editedItem.ValorPago = this.editedItem.ValorPago;
				new_editedItem.DataPagamento = this.editedItem.DataPagamento;
				new_editedItem.IdBanco = this.selListaBancos.nCodCC;
				axios({ method: "post", data: new_editedItem, url: "/api/Faturamento/Save" })
					.then((response) => {
						this.$toast.success("Fatura baixada no sistema.", { timeout: 4000 });
						this.DialogBaixa = false;
						this.Index();
						debugger;
						axios.get("/api/Faturamento/EfetuarBaixa/".concat(this.editedItem.Id).concat("/").concat(new_editedItem.IdBanco))
							.then((response) => {
								this.$toast.success("Fatura baixada no Omie", { timeout: 4000 });
								this.DialogBaixa = false;
								this.Index();
							})
							.catch((error) => {});
					})
					.catch((error) => {
						console.log(error);
					});
			});
		},

		desconto(valor, desc) {
			return valor - (valor * desc) / 100;
		},

		DataHoje() {
			return moment().format();
		},

		IsDataValid(data) {
			try {
				if (data == "0001-01-01T00:00:00" || data == null) {
					return false;
				}
				const date = new Date(data.trim());
				return date instanceof Date && !isNaN(date);
			} catch {
				return false;
			}
		},
		Edit(Id) {
			this.$router.push("/Faturamento/Edit/".concat(Id));
		},
		Index() {
			this.Pagination.Filtro.DataInicio = this.DataInicio;
			this.Pagination.Filtro.DataFim = this.DataFim;
			if (this.Pagination.Filtro.EmAberto == "true") {
				this.LabelFiltro = "Filtrar Pagos";
			} else {
				this.LabelFiltro = "Filtrar Em Aberto";
			}
			//Pega os dados da controller, action Index
			axios.post("/api/Faturamento/Index", this.Pagination).then((response) => {
				//Pega os dado do Grid
				this.GridData = response.data.Data;
				//Pega os dados da Paginação
				this.Pagination = response.data.Pagination;
				this.PegaBancos();
			});
		},
		PegaBancos() {
			axios.get("/api/Clicktime/ConsultaContasCorrentes/")
				.then((response) => {
					this.ListaBancos = JSON.parse(response.data.Data.contascorrentes);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		CalculaComissao(item) {
			axios.get("/api/Comissionamentos/CalculaComissao/".concat(item.Id))
				.then((response) => {
					this.$toast.clear();
					if (response.status == 200) {
						this.$toast.success("Comissionamentos Calculados.", { timeout: 4000 });
					} else {
						this.$toast.error("Erro ao calcular o comissionamento.", { timeout: 4000 });
					}
					this.Index();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		ExcluirFaturamento(item) {
			axios.get("/api/Faturamento/ExcluirFaturamento/".concat(item.Id)).then((response) => {
				this.$toast.clear();
				if (response.data.codigo_status == 0) {
				}
				this.$toast.success("Faturamento excluído e retornado ao preliminar.", { timeout: 4000 });
				this.Index();
			});
		},
		EnvioFaturamento(item) {
			axios.get("/api/Faturamento/EnviarFaturamento/".concat(item.Id)).then((response) => {
				this.$toast.clear();
				if (response.data.codigo_status == 0) {
				}
				this.$toast.success("Faturamento enviado ao Cliente.", { timeout: 4000 });
				this.Index();
			});
		},
		RowClass(item) {
			if (item.Ativo == false) {
				return "rowdisabled";
			}
		},
		FormataMoeda2(valor, moeda) {
			var sPreMoeda = "";
			if (moeda == 1) {
				sPreMoeda = "R$ ";
			} else if (moeda == 2) {
				sPreMoeda = "U$ ";
			}
			return sPreMoeda.concat(
				parseFloat(valor)
					.toFixed(2)
					.replace(".", ",")
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
			);
		},
		FormataMoeda(value, locale = 1, number = false) {
			// Definir separadores conforme o locale
			const thousandSeparator = locale === 1 ? "." : ",";
			const decimalSeparator = locale === 1 ? "," : ".";
			const currencySymbol = locale === 1 ? "R$" : "U$";
			// Separar a parte inteira e a decimal
			value = parseFloat(value).toFixed(2);
			let [integer, decimal] = value.split(".");
			// Aplicar regex para adicionar separador de milhar
			integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
			// Retornar número formatado
			if (number) return `${decimal ? `${integer}${decimalSeparator}${decimal}` : integer}`;
			else return `${currencySymbol} ${decimal ? `${integer}${decimalSeparator}${decimal}` : integer}`;
		},
		GerarContasReceber(IdControle) {
			this.$toast.success("Criando Contas a Receber, Aguarde...", { timeout: 3000 });
			axios.get("/api/Faturamento/GerarContasReceber/".concat(IdControle)).then((response) => {
				var sJsonRetorno = response.data;
				if (sJsonRetorno.codigo_status == 0) {
					this.$toast.success("Contas a Receber gerada.", { timeout: 3000 });
				} else {
					this.$toast.error("Processamento com erro,\n fatura já emitida ou sem contato com o Omie", { timeout: 5000 });
				}
				this.Index();
			});
		},

		DownloadFaturamento(item) {
			axios({ method: "post", responseType: "blob", url: "/api/Listas/DownloadFaturamento/".concat(item.Id) }).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				var sNomeArquivo = response.headers["content-disposition"]
					.split(";")[1]
					.split("=")[1]
					.replaceAll('"', "")
					.replace(/^.*[\\\/]/, "");
				const link = document.createElement("a");
				link.href = url;
				//link.setAttribute("download", item.Cliente.Nome + "_" + item.Caso.Nome + ".pdf");
				link.setAttribute("download", sNomeArquivo);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			});
		},
		// RowSelect para edição
		RowSelectClick: function (item, row) {
			this.$router.push("/Faturamento/Edit/".concat(item.Id));
		},
		ConverteMoedaPtBr(valor) {
			return new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(valor));
		},
		ConverteMoedaUS(valor) {
			return parseFloat(valor.replace(/\./g, "").replace(",", ".")).toFixed(2);
		},
	},
	mounted: function () {
		this.Index();
	},
	computed: {
		computedHeaders() {
			var NewHeader = this.Header;
			if (this.Pagination.Filtro.EmAberto == "true") {
				NewHeader = NewHeader.filter((h) => h.value !== "Action8");
				NewHeader = NewHeader.filter((h) => h.value !== "ValorPago");
			} else {
				NewHeader = NewHeader.filter((h) => h.value !== "Action2");
				NewHeader = NewHeader.filter((h) => h.value !== "Action");
			}

			return NewHeader;
		},
	},
};
</script>
