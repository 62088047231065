<template>
  <div class="bordaappbar">
    <v-data-table class="elevation-1 row-pointer" @click:row="RowSelectClick" :headers="Header" mobile-breakpoint="1" :items="GridData" :item-class="RowClass" :sort-by.sync="Pagination.sortBy" :must-sort="true" :sort-desc.sync="Pagination.sortDesc" item-key="Id" :options.sync="Pagination" :server-items-length="Pagination.itemsLength" :footer-props="{ itemsPerPageOptions: RowsPerPageItems }" v-on:update:page="Index()" v-on:update:items-per-page="Index()" v-on:update:sort-by="Index()" v-on:update:sort-desc="Index()">
      <template v-slot:top>
         <v-toolbar dense flat class="mt-5">
          <v-col col="8" md="6" lg="6" offset="0" offset-md="2" offset-lg="2">
            <v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="Procurar" v-model="Pagination.Filtro.Busca" @change="Index()"></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn to="/Cadastro/Casos/Edit/0" color="primary">
            Nova
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <br />
      </template>

      <template v-slot:[`item.MoedaFaturamento`]="{ item }">
        <span class="text-no-wrap">
          <span v-if="item.MoedaFaturamento==1">R$</span>
          <span v-if="item.MoedaFaturamento==2">U$</span>
        </span>
      </template>

      <template v-slot:[`item.ValorLimite`]="{ item }">
        <span class="text-no-wrap">
          <span v-if="item.MoedaFaturamento==1" v-show="item.ValorLimite>0">R$</span>
          <span v-if="item.MoedaFaturamento==2" v-show="item.ValorLimite>0">U$</span>
          <span v-show="item.ValorLimite>0"> {{item.ValorLimite}}</span>
        </span>
      </template>

      <template v-slot:[`item.Action`]="{ item }">
        <v-icon @click="Edit(item.Id)" color="teal">mdi-pencil</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Caso",
  data() {
    return {
      GridData: [],
      Header: [
        { text: "Id", value: "Id", width: "100", sortable: false },
        { text: "Cliente", value: "Cliente.Nome", width: "30%", sortable: false },
        { text: "Identificação", value: "Nome", width: "30%", sortable: false },
        { text: "Moeda", value: "MoedaFaturamento", width: "100", sortable: false, align: "center" },
        { text: "Limite", value: "ValorLimite", width: "200", sortable: false, align: "right" },
        { text: "Advogado Responsável", value: "Advogado.Nome", width: "25%", sortable: false },
        { text: "", value: "Action", sortable: false },
      ],
      RowsPerPageItems: [5, 10, 20, 30, 40, 50, 100, 200],
      Pagination: {
        page: 1,
        itemsPerPage: 50,
        pageCount: 1,
        itemsLength: 0,
        sortBy: ["Id"],
        sortDesc: [false],
        Filtro: {
          Busca: "",
          Ativos: "1",
        },
      },
    };
  },
  components: {},
  methods: {
    Edit(Id) {
      this.$router.push("/Cadastro/Casos/Edit/".concat(Id));
    },
    Index() {
      //Pega os dados da controller, action Index
      axios.post("/api/Cadastro/Casos/Index", this.Pagination).then((response) => {
        //Pega os dado do Grid
        this.GridData = response.data.Data;

        //Pega os dados da Paginação
        this.Pagination = response.data.Pagination;
      });
    },
    RowClass(item) {
      if (item.Ativo == false) {
        return "rowdisabled";
      }
    },
    // RowSelect para edição
    RowSelectClick: function (item, row) {
      this.$router.push("/Cadastro/Casos/Edit/".concat(item.Id));
    },
  },
  mounted: function () {
    this.Index();
  },
};
</script>
