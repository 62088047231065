<template>
	<div>
		<v-card>
			<v-card-text class="pl-1 pr-1">
				<v-col col="12">
					<v-card>
						<v-app-bar color="primary" dense>
							<v-toolbar-title class="white--text">Caso</v-toolbar-title>
						</v-app-bar>
						<v-card-text class="bordaappbar">
							<v-row dense>
								<v-col col="2" md="2" lg="2" offset="0" offset-md="2" offset-lg="2">
									<v-text-field outlined dense hide-details label="Id" v-model="Model.Id" readonly class="fundo-readonly"></v-text-field>
								</v-col>

								<v-col col="3" md="3" lg="2">
									<v-switch hide-details inset label="Ativo" color="primary" v-model="Model.FlagAtivo" :false-value="0" :true-value="1"></v-switch>
								</v-col>

								<v-col col="3" md="3" lg="2">
									<v-switch hide-details inset label="Cobrança" color="warning" v-model="Model.FlagCobranca" :false-value="0" :true-value="1"></v-switch>
								</v-col>
								<v-col cols="2">
									<v-select outlined dense label="Moeda Faturamento" v-model="Model.MoedaFaturamento" :items="Moedas" item-text="MoedaNome" item-value="MoedaCodigo"></v-select>
								</v-col>
							</v-row>

							<v-row dense>
								<v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
									<v-text-field outlined dense hide-details label="Nome" v-model="Model.Nome"></v-text-field>
								</v-col>
							</v-row>

							<v-row dense>
								<v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
									<v-text-field outlined dense hide-details label="Nome de Impressão" v-model="Model.NomeImpressao"></v-text-field>
								</v-col>
							</v-row>

							<v-row dense>
								<v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
									<v-autocomplete return-object :items="Clientes" item-text="Nome" item-value="Id" v-model="Model.Cliente" label="Cliente" dense outlined hide-details></v-autocomplete>
								</v-col>
							</v-row>

							<v-row dense>
								<v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
									<v-autocomplete return-object :items="Advogados" item-text="Nome" item-value="Id" v-model="Model.Advogado" label="Advogado" dense outlined hide-details></v-autocomplete>
								</v-col>
							</v-row>

							<v-row dense>
								<v-col col="12" md="4" lg="4" offset="0" offset-md="2" offset-lg="2">
									<dtPicker label="Data Abertura" v-model="Model.DataAbertura"></dtPicker>
								</v-col>
								<v-col col="12" md="4" lg="4">
									<dtPicker label="Data Fechamento" v-model="Model.DataFechamento"></dtPicker>
								</v-col>
							</v-row>

							<v-row dense class="mb-3">
								<v-col cols="12" md="2" lg="2" offset="0" offset-md="2" offset-lg="2">
									<v-switch hide-details inset label="Limite de Faturamento" color="warning" v-model="Model.LimiteFaturamento" :false-value="0" :true-value="1"></v-switch>
								</v-col>
								<v-col col="12" md="2" lg="2">
									<v-currency-field v-show="Model.LimiteFaturamento" :prefix="Model.ValorLimite == 1 ? 'R$' : 'U$'" outlined dense hide-details label="Valor Limite" v-model="Model.ValorLimite"></v-currency-field>
								</v-col>
							</v-row>

							<v-row dense>
								<v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
									<v-textarea outlined dense hide-details rows="3" label="Descrição" v-model="Model.Descricao"></v-textarea>
								</v-col>
							</v-row>

							<v-row dense>
								<v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
									<v-textarea outlined dense hide-details rows="3" label="Observação" v-model="Model.Observacao"></v-textarea>
								</v-col>
							</v-row>

							<v-row dense>
								<v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
									<v-textarea outlined dense hide-details rows="8" label="Endereço Faturamento" v-model="Model.EnderecoFaturamento"></v-textarea>
								</v-col>
							</v-row>

							<!-- COMISSAO -->
							<v-col>
								<v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
									<v-data-table dense class="elevation-0 bordagrid" item-class="row-pointer" mobile-breakpoint="1" @click:row="RowComissaoClick" :headers="HeaderComissao" sort-by="Nome" :items="Model.CasoComissao" item-key="Id" single-select hide-default-footer disable-pagination disabled-filtering :no-data-text="'Nenhuma informação disponível/cadastrada'">
										<template v-slot:top>
											 class="mt-5"
												<v-toolbar-title>Comissão</v-toolbar-title>
												<v-spacer></v-spacer>
												<template>
													<v-autocomplete return-object :items="Advogados" @click="NovaComissaoCLick()" item-text="Nome" item-value="Id" v-model="NovaComissaoAdv" label="Advogado" dense outlined hide-details></v-autocomplete>
													<v-col col="1">
														<v-text-field outlined dense hide-details label="Comissão Indicação" v-model="NovaComissaoInd" type="number" suffix="%"></v-text-field>
													</v-col>
													<v-col col="1">
														<v-text-field outlined dense hide-details label="Comissão Atuação" v-model="NovaComissaoAtu" type="number" suffix="%"></v-text-field>
													</v-col>
													<v-btn @click="AddAdvogado()" color="primary"><v-icon>mdi-plus</v-icon></v-btn>
												</template>
											</v-toolbar>
											<hr />
										</template>
										<template v-slot:[`item.Action`]="{ item }">
											<v-icon @click.stop="DelComissao(item)" color="error">mdi-trash-can</v-icon>
										</template>
									</v-data-table>
								</v-col>
							</v-col>

							<!-- CONTATOS -->
							<v-col>
								<v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
									<v-data-table dense class="elevation-0 bordagrid" item-class="row-pointer" mobile-breakpoint="1" :headers="HeaderContato" sort-by="Nome" :items="Model.CasoContato" item-key="Id" single-select hide-default-footer disable-pagination disabled-filtering :no-data-text="'Nenhuma informação disponível/cadastrada'">
										<template v-slot:top>
											 class="mt-5"
												<v-toolbar-title>Contatos</v-toolbar-title>
												<v-spacer></v-spacer>
												<template>
													<v-col></v-col>
													<v-col>
														<SelectButton :Itens="Contatos" Label="Contato" ItemText="Nome" ItemValue="Id" :ItemSubTitleItens="['Email', 'Telefone1', 'Telefone1']" v-model="NovoContato" BtnText="Criar Novo Contato" BtnIcon="mdi-account-plus" v-on:clickbtn="DialogNovoContatoSistema = true"></SelectButton>
													</v-col>
													<v-btn @click="AddContato()" color="primary"><v-icon>mdi-plus</v-icon></v-btn>
												</template>
											</v-toolbar>
											<hr />
										</template>
										<template v-slot:[`item.Action`]="{ item }">
											<v-icon @click.stop="DelContato(item)" color="error">mdi-trash-can</v-icon>
										</template>
									</v-data-table>
								</v-col>
							</v-col>

							<v-row dense class="pt-16">
								<v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
									<v-row>
										<v-col>
											<v-btn block @click="ShowConfirmaExcluir = true" color="error" v-if="Model.Id != 0">Excluir Caso</v-btn>
										</v-col>
										<v-spacer></v-spacer>
										<v-col>
											<v-btn block @click="Cancelar()" color="warning">Cancelar</v-btn>
										</v-col>
										<v-spacer></v-spacer>
										<v-col>
											<v-btn block @click="ShowConfirmaSalvar = true" color="primary">{{ Model.Id == 0 ? "Incluir" : "Salvar" }}</v-btn>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-card-text>
		</v-card>

		<v-dialog v-model="DialogNovoContatoSistema" width="600">
			<v-card>
				<v-app-bar color="primary" dense>
					<v-toolbar-title class="white--text">Novo Contato - Sistema</v-toolbar-title>
				</v-app-bar>
				<v-card-text>
					<v-container class="pt-10">
						<template>
							<v-row dense>
								<v-col cols="12">
									<v-text-field outlined dense hide-details label="Nome" v-model="NovoContatoSistema.Nome"></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<v-text-field outlined dense hide-details label="E-Mail" v-model="NovoContatoSistema.Email"></v-text-field>
								</v-col>
							</v-row>

							<v-card-actions class="pt-10">
								<v-spacer></v-spacer>
								<v-btn x-large color="warning" @click="DialogNovoContatoSistema = false">Cancelar</v-btn>
								<v-btn x-large color="primary" @click="NovoContatoSistemaSave()">Salvar</v-btn>
							</v-card-actions>
						</template>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>

		<confirm Msg="Excluir?" :Show="ShowConfirmaExcluir" v-on:confirm="Delete(), (ShowConfirmaExcluir = false)" v-on:cancel="ShowConfirmaExcluir = false"></confirm>
		<confirm Msg="Salvar?" :Show="ShowConfirmaSalvar" v-on:confirm="Save(), (ShowConfirmaSalvar = false)" v-on:cancel="ShowConfirmaSalvar = false"></confirm>
	</div>
</template>

<script>
import axios from "axios";

export default {
	name: "CasoEdit",
	components: {},
	props: {
		idCaso: {
			type: Number,
			default: 0,
		},
		fromRelatorio: {
			type: Boolean,
			defaul: false,
		},
	},
	data() {
		return {
			ShowDialog: true,
			Model: {},
			DialogNovoContatoSistema: false,
			NovoContatoSistema: {},
			ShowConfirmaExcluir: false,
			ShowConfirmaSalvar: false,
			NovaComissaoAdv: "",
			NovaComissaoAtu: 0,
			NovaComissaoInd: 0,
			NovoContato: null,
			TemLimite: false,
			Clientes: [],
			Advogados: [],
			Contatos: [],
			GridAdvogados: {},
			NovaComissao: [],
			Moedas: [
				{ MoedaCodigo: 1, MoedaNome: "R$ - Real" },
				{ MoedaCodigo: 2, MoedaNome: "U$ - Dólar" },
			],
			HeaderComissao: [
				{ text: "Advogado", value: "Advogado.Nome", sortable: true, width: "60%" },
				{ text: "Comissão Indicação", value: "ComissaoIndicacao", sortable: true, width: "20%", align: "center" },
				{ text: "Comissão Atuação", value: "ComissaoAtuacao", sortable: true, width: "20%", align: "center" },
				{ text: "", value: "Action", sortable: false, width: "70px", align: "center" },
			],
			HeaderContato: [
				{ text: "Nome", value: "Contato.Nome", sortable: true, width: "60%" },
				{ text: "E-Mail", value: "Contato.Email", sortable: true, width: "20%" },
				{ text: "", value: "Action", sortable: false, width: "70px", align: "center" },
			],
		};
	},
	methods: {
		NovaComissaoCLick() {
			this.NovaComissaoInd = 0;
			this.NovaComissaoAtu = 0;
		},
		AddAdvogado() {
			if (!this.NovaComissaoAdv || this.NovaComissaoAtu < 0 || this.NovaComissaoInd < 0) {
				this.$toast.error("Advogado comissionado não válido", { timeout: 5000 });
				return;
			}
			if (this.NovaComissaoAtu > 99 || this.NovaComissaoInd > 99) {
				this.$toast.error("Comissão não pode ser maior que 99%", { timeout: 5000 });
				return;
			}
			let membro = this.Advogados.find((x) => x.IdAdvogado == this.NovaComissaoAdv.Id);
			if (membro) {
				this.$toast.error("Advogado já cadastrado", { timeout: 5000 });
				return;
			}
			this.Model.CasoComissao.push({
				Id: 0,
				IdAdvogado: this.NovaComissaoAdv.Id,
				ComissaoIndicacao: Number(this.NovaComissaoInd),
				ComissaoAtuacao: Number(this.NovaComissaoAtu),
				Advogado: { Id: this.NovaComissaoAdv.Id, Nome: this.NovaComissaoAdv.Nome },
			});
			this.NovaComissaoInd = null;
			this.NovaComissaoAtu = null;
			this.NovaComissaoAdv = null;
		},
		DelComissao(Item) {
			this.Model.CasoComissao.splice(this.Model.CasoComissao.indexOf(Item), 1);
		},
		AddContato() {
			if (!this.NovoContato) {
				this.$toast.error("Contato não selecionado", { timeout: 5000 });
				return;
			}

			let membro = this.Model.CasoContato.find((x) => x.Contato.Id == this.NovoContato.Id);
			if (membro) {
				this.$toast.error("Contato já cadastrado", { timeout: 5000 });
				return;
			}
			this.Model.CasoContato.push({
				Id: 0,
				IdCaso: this.Model.Id,
				IdContato: this.NovoContato.Id,
				Contato: this.NovoContato,
			});

			this.NovoContato = null;
		},
		DelContato(Item) {
			this.Model.CasoContato.splice(this.Model.CasoContato.indexOf(Item), 1);
		},
		async NovoContatoSistemaSave() {
			await axios({ method: "post", data: this.NovoContatoSistema, url: "/api/Cadastro/Casos/AdicionaContato" })
				.then((response) => {
					this.GetListas();
					this.DialogNovoContatoSistema = false;
				})
				.catch((error) => {
					let a = 1;
				});
		},
		Cancelar() {
			// if (!this.fromRelatorio) {
			this.$router.push("/Cadastro/Casos/Index").catch(() => {});
			this.Index();
			// } else {
			// this.$emit("retorno");
			// }
		},
		Edit(Id) {
			axios.get("/api/Cadastro/Casos/Edit/".concat(Id)).then((response) => {
				//Pega os dados do response e joga no objeto Model
				this.Model = response.data.Data;
			});
		},
		Save() {
			this.Model.FlagCaso = "P";
			axios({ method: "post", data: this.Model, url: "/api/Cadastro/Casos/Save" })
				.then((response) => {
					if (!this.fromRelatorio) {
						this.$toast.success("Registro salvo com sucesso", { timeout: 4000 });
						this.Index();
					} else {
						this.$emit("retorno");
					}
				})
				.catch((error) => {
					let a = 1;
				});
		},
		Delete() {
			axios({ method: "delete", data: this.Model, url: "/api/Cadastro/Casos/Delete" })
				.then((response) => {
					this.Index();
				})
				.catch((error) => {
					//
				});
		},
		Index() {
			this.$router.push("/Cadastro/Casos/Index");
		},
		GetListas() {
			const instance = axios.create();
			const GetAdvogados = instance.get("/api/Listas/Advogados");
			const GetClientes = instance.get("/api/Listas/Clientes");
			const GetContatos = instance.get("/api/Listas/Contatos");
			axios.all([GetAdvogados, GetClientes, GetContatos])
				.then(
					axios.spread((...responses) => {
						responses[0].data.forEach((item) => {
							this.Advogados.push(item);
						});

						responses[1].data.forEach((item) => {
							this.Clientes.push(item);
						});

						responses[2].data.forEach((item) => {
							this.Contatos.push(item);
						});
					})
				)
				.catch((errors) => {});
		},
		RowComissaoClick: function (item, row) {
			if (!this.IsReadOnly) {
				this.editedIndex = this.Model.Partes.findIndex((p) => p.Id == item.Id);
				this.editedItem = Object.assign({}, item);
				this.DialogEditPartes = true;
			}
		},
	},
	mounted: function () {
		//Pega a Id enviada pela rota
		let Id = this.$route.params.id;
		this.GetListas();

		if (Id != null) {
			//se for null, volta para o Index
			this.Edit(Id);
		} else {
			//this.$router.push("/Cadastro/Casos/Index");
			this.Edit(this.idCaso);
		}
	},
};
</script>
